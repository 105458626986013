@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  font-family: 'Outfit', sans-serif;
  /* font-size: 62.5%; */
}

@media only screen and (min-width: 768px) {
  :root {
    font-size: 75%;
    /* Establece 1rem como 12px para dispositivos de tamaño tablet */
  }
}

@media only screen and (min-width: 1024px) {
  :root {
    font-size: 87.5%;
    /* Establece 1rem como 14px para dispositivos de tamaño desktop */
  }
}

@media only screen and (min-width: 1200px) {
  :root {
    font-size: 95%;
  }
}

@media only screen and (min-width: 1536) {
  :root {
    font-size: 1vw;
  }
}

.node__root > circle {
  fill: #EC6523;
  r:20;
}

.node__branch > circle {
  fill: #e59268;
}

.node__leaf > circle {
  /* fill: rgb(255, 255, 255); */
  /* Let's also make the radius of leaf nodes larger */
}
.owl-dots {
  /* background-color: red; */
  position: absolute;
  display: flex;
  position: relative;
  margin-top: -2rem;
  z-index: 50;
  justify-content: center;
  gap: 6px;
}

.owl-dot {
  border: none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.owl-dot.active {
  background-color: #EC6523;
  width: 2rem;
  border-radius: 1rem;
  cursor: auto;
}
