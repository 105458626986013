.owl-dots {
  /* background-color: red; */
  position: absolute;
  display: flex;
  position: relative;
  margin-top: -2rem;
  z-index: 50;
  justify-content: center;
  gap: 6px;
}

.owl-dot {
  border: none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.owl-dot.active {
  background-color: #EC6523;
  width: 2rem;
  border-radius: 1rem;
  cursor: auto;
}